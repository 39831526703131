import Error from '~/public/images/error.svg';

interface ErrorStateProps {
  message: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ message }) => {
  return (
    <div className="flex flex-col text-center font-semibold text-body-1">
      <span className="h-full w-full" style={{ maxWidth: 496, maxHeight: 329 }}>
        <Error />
      </span>
      <h5 className="my-6 px-3">{message}</h5>
    </div>
  );
};
