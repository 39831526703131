"use client";

import { ErrorState } from "~/components/errors/ErrorState";
import { Button } from "~/components/ui/Button";
import { useCommonDict } from "~/hooks/useTranslations";

export default function NotFound() {
  const { t } = useCommonDict();

  return (
    <div className="align-center flex h-full w-full flex-col items-center justify-center p-4">
      <ErrorState message={t("fpErrors.404")} />
      <div className="min-h-8"></div>

      <Button
        variant="outline"
        size="lg"
        className="mt-8"
        onClick={() => window.history.back()}
      >
        {t("fpBack")}
      </Button>
    </div>
  );
}
