import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const buttonVariants = cva(
  "inline-flex gap-1 items-center justify-center whitespace-nowrap rounded-lg text-sm font-bold uppercase transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-70 disabled:text-neutral-weak transform active:scale-95",
  {
    variants: {
      variant: {
        default:
          "bg-button-primary text-button-primary shadow-sm hover:opacity-90",
        secondary:
          "bg-button-secondary text-button-secondary shadow-sm hover:opacity-90",
        destructive:
          "bg-destructive text-button-destructive shadow-sm hover:opacity-90",
        outline:
          "border border-neutral bg-button-tertiary shadow-sm hover:bg-tertiary-weak",
        error:
          "border border-error text-error shadow-sm hover:opacity-90 disabled:border-error disabled:text-error",
        ghost: "hover:bg-tertiary-weak",
        link: "underline-offset-4 hover:underline",
      },
      size: {
        default: "h-9 px-4 py-2",
        xs: "h-6 rounded-full px-3 py-1 text-xs",
        sm: "h-8 px-3 text-xs",
        lg: "h-10 px-8",
        xl: "h-12 px-8 text-lg",
        icon: "h-6 w-6 bg-transparent p-0 shadow-none",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
